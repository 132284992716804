
import * as React from "react"
import { Link } from "gatsby"

const Paginate = ({ currentPage, numPages, limit, skip }) => {
  return (
    (() => {
      var output = [], _index, _class;
      for (let index = 1; index < numPages + 1; index++) {
        _index = index === 1 ? '' : index;
        _class = currentPage === index ? 'current px-2 py-1 m-1 bg-primary text-900 fw-bold rounded-3' : 'px-2 py-1 m-1 bg-800 rounded-3 =2xs';
        output.push(<Link key={index} className={_class} to={`/works/${_index}`}>{index}</Link>);
      }
      return (output);
    })()
  )
}

export default Paginate