import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Pagination from "../components/pagination"
import Paginate from "../components/paginate"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Badge from "../components/badge"
import Img from "gatsby-image"

const worksIndex = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  var postCount = 0;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle} numPages={pageContext.numPages}>
        <Seo title="Works." />
        <div className="container container-max-md">
          <Bio />
          <p className="mt-5">
            No works found.
          </p>
        </div>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} numPages={pageContext.numPages}>
      <Seo title={`All works. ${pageContext.currentPage > 1 ? ` | Page (` + pageContext.currentPage + `)` : ''}`} />
      <div className="container container-max-md">
        <h1 className="visually-hidden">All works. | Onur Boz</h1>
        <h2 className="h4">Boz Works</h2>
        <p className="=xs">
          The projects I have developed for many countries, especially Turkey, America, England, Sweden, Germany, Estonia,
          are listed below. These projects include primarily Web Design, Mobile application interface designs, Corporate identity,
          Illustration, E-mail templates, Business Cards, Brochures, Logo and Icon designs, CMS Installations and developments,
          Back-end Developments, System configurations and Technical support services.
          <i>The preview links are reproduced versions of pure HTML files.</i>
        </p>
        <p className="=2xs mb-4">
          Most of the works listed below are licensed under the name <b>Onur Boz</b>, <b>Boz</b>, <b title="boz.works">Boz Works</b>. The rest are prepared on behalf of the end user for various agencies. The content and images are purely for promotional purposes and their original owners are the relevant individuals and institutions, and their copyright policies will apply.
        </p>

        <ol className="list-unstyled">
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug
            let workBanner = post.frontmatter.workBanner.childImageSharp.fluid
            // let videoUrl = post.frontmatter.workVideo ? "/" + post.frontmatter.workVideo + ".mp4" : null;
            let videoUrl = post.frontmatter.workVideo ? "https://cdn.onurboz.com/static/" + post.frontmatter.workVideo + '/' + post.frontmatter.workVideo + ".mp4" : null;

            if (typeof window !== `undefined` && !document.querySelector('body').classList.contains('expand-video-listener-initialized')) {
              document.addEventListener('click', function (e) {
                var dummy = e.target.closest('.browser .video');
                dummy = dummy && dummy.closest('.browser');
                dummy && dummy.classList.toggle('expand');
              });
              document.querySelector('body').classList.add('expand-video-listener-initialized')
            }

            postCount++

            return (
              <li key={post.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                  lang={post.frontmatter.lang}
                >
                  <header>
                    <h2 className="h4 mb-3 text-400 text-primary" itemProp="headline">
                      <Link to={post.fields.slug} itemProp="url">
                        {title}
                      </Link>
                    </h2>
                    <h3 className="h4 m-0 visually-hidden" itemProp="headline">{post.frontmatter.description}</h3>
                  </header>

                  <section>
                    <div className="browser user-select-none">
                      <div aria-hidden="true" className="controls" data-title={post.frontmatter.workTitle}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <div className="banner">
                        <Img className="borderless pe-none" fluid={workBanner} />
                        {videoUrl !== null &&
                          <div className="video" title={post.frontmatter.workTitle}>
                            <div className="browser user-select-none pe-none">
                              <div aria-hidden="true" className="controls" data-title={post.frontmatter.workTitle}>
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                              <div className="ratio ratio-4x3 pe-none">
                                <video poster={workBanner.base64} autoPlay={true} muted loop playsInline>
                                  <source src={videoUrl}
                                    type="video/mp4"></source>
                                  Sorry, your browser doesn't support embedded videos.
                                </video>

                              </div>
                            </div>
                          </div>
                        }
                      </div>

                      <Badge badges={post.frontmatter.workBadge || []} />

                      <div className="bottom bg-800">
                        <div className="row gx-0">
                          <div className="col-6 d-flex align-items-center">
                            <div className="w-100">
                              <h3 className="h5 m-0 text-truncate">
                                {post.frontmatter.workTitle}
                              </h3>
                              <p className="text-muted m-0 text-truncate"><small>{post.frontmatter.workDate}</small></p>
                            </div>
                          </div>
                          <div className="col-6 d-flex align-items-center justify-content-end">
                            <Link className="d-inline-flex py-2 px-4 bg-700 hover--bg-600 rounded text-white mw-100" to={post.fields.slug} itemProp="url">
                              <span className="text-truncate">View details</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </article>

                {postCount > 0 && postCount < posts.length && <hr className="my-4 bg-primary"></hr>}
              </li>
            )
          })}
        </ol>
        <div className="d-inline-flex justify-content-center =xs text-600 mt-4 rounded-3">
          Page {pageContext.currentPage} of {pageContext.numPages}
        </div>
        <nav className="py-1 mw-100" aria-label="Navigating other pages">
          <div className="d-flex flex-wrap align-items-center mw-100 w-100">
            <Paginate
              currentPage={pageContext.currentPage}
              numPages={pageContext.numPages}
              limit={pageContext.limit}
              skip={pageContext.skip}
            />
          </div>
        </nav>
      </div>
      <Pagination
        currentPage={pageContext.currentPage}
        numPages={pageContext.numPages}
        limit={pageContext.limit}
        skip={pageContext.skip}
      />
    </Layout>
  )
}

export default worksIndex

export const pageQuery = graphql`
  query pageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {fileAbsolutePath: {regex: "/(\/content\/works)/.*.md$/"}}
      limit: $limit
      skip: $skip
      ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          description
          lang
          type
          workDate
          workType
          workTitle
          workBadge
          workVideo
          workBanner {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          workDemo
        }
      }
    }
  }
`
