
import * as React from "react"
import { Link } from "gatsby"

const Pagination = ({ currentPage, numPages, limit, skip }) => {

  const PreviousPageLink = () => {
    if (currentPage > 2) {
      return (
        <Link
          className="d-inline-flex py-1 =xs px-3 bg-700 hover--bg-600 rounded text-white mw-100 w-100 user-select-none"
          title="Go to previous page"
          to={`/works/${currentPage - 1}`}>
          ←<span className="d-none d-sm-block"> Previous page</span>
        </Link>
      )
    } else if (currentPage === 1) {
      return (
        <span aria-hidden="true" className="disabled d-inline-flex py-1 =xs px-3 bg-800 rounded text-500 mw-100 w-100 user-select-none">
          ←<span className="d-none d-sm-block"> Previous page</span>
        </span>
      )
    } else {
      return (
        <Link
          className="d-inline-flex py-1 =xs px-3 bg-700 hover--bg-600 rounded text-white mw-100 w-100 user-select-none"
          title="Go to previous page"
          to={`/works`}>
          ←<span className="d-none d-sm-block"> Previous page</span>
        </Link>
      )
    }
  }

  const NextPageLink = () => {
    if (currentPage < numPages) {
      return (
        <Link
          className="d-inline-flex py-1 =xs px-3 bg-700 hover--bg-600 rounded text-white mw-100 w-100 user-select-none"
          title="Go to next page"
          to={`/works/${currentPage + 1}`}>
          <span className="d-none d-sm-block">Next page </span>→
        </Link>
      )
    } else {
      return (
        <span aria-hidden="true" className="disabled d-inline-flex py-1 =xs px-3 bg-800 rounded text-500 mw-100 w-100 user-select-none">
          <span className="d-none d-sm-block">Next page </span>→
        </span>
      )
    }
  }

  const Headings = () => (
    <Link to="/" title="Onur Boz Home Page" className="link-400 pe-2">
      <svg aria-hidden="true" fill="currentColor" width="50" height="26" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 925.6 535.3"><path d="M539.6 267.6L462.5 134l.1-.2H309.2l-.1-.1L386.3 0H231.8l.1.2-77.1 133.6h-.7l.1.2-77.1 133.6H77h.1l.1.1L.1 401.4H0h.1L77.2 535l-.1.2H539.8l-77.2-133.7.1-.1h.1-.1l-.1-.1 77-133.7zM308.2 401.4H154.4l-.1-.1 77.1-133.6H385.3l.1.1-77.2 133.6zM925.6 401.4H771.4v-.1l77.1-133.6v-.1L771.4 134l.1-.2H617l.1.2-77.2 133.7h154.2l.1.1L617 401.4v.1l.1.1-77.2 133.7h308.6l-.1-.2z" /></svg>
    </Link>
  )

  return (
    <nav aria-label="Cross-page navigation menu" className={`${numPages<2 ? `d-none ` : ''}cross-page--pagination px-0 container container-max-md position-fixed bottom-0 start-50 translate-middle-x`}>
      <div className="d-flex flex-nowrap align-items-center p-3 bg-800 rounded-top">
        <Headings />
        <div className="row g-2 align-items-center ms-auto">
          <div className="col-auto order-2 ms-auto d-flex align-items-center">
            <PreviousPageLink />
          </div>
          <div className="col-auto order-1 order-lg-3 d-flex align-items-center">
            <span className="information =xs mx-3 text-500 w-100">
              Page <span className="px-2 bg-700 text-400 rounded fw-fw-bolder">{currentPage}</span> of {numPages}
            </span>
          </div>
          <div className="col-auto order-4 d-flex align-items-center">
            <NextPageLink />
          </div>
        </div>
      </div>
    </nav>
  )
};

export default Pagination